@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: 'Pretendard Variable';
        src: url(../src/assets/fonts/PretendardVariable.woff2) format('woff2');
    }




    html {
        font-family: Pretendard Variable, Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, sans-serif !important;
    }


}